export default [
    {
        header: 'Sales',
        icon: 'ShoppingCartIcon',
        i18n: 'Sales',
        items: [
            {
                url: '/sales/coupons',
                name: 'Coupons',
                slug: 'coupons',
                icon: 'ZapIcon',
                i18n: 'Coupons'
            }
        ]
    }
]

